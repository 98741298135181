<template>
    <vue-plotly style="width=100%;" :data="data" :layout="layout" :options="options"/>
</template>

<script>

import VuePlotly from '@statnett/vue-plotly'

export default {
  name: 'StackedBarChart',
  components: { VuePlotly },
  props: {
    name1: String,
    x1: Array,
    xx1: Array,
    y1: Array,
    name2: {
      type: String,
      default: ''
    },
    x2: {
      type: Array,
      default () {
        return []
      }
    },
    xx2: {
      type: Array,
      default () {
        return []
      }
    },
    y2: {
      type:Array,
      default () {
        return []
      }
    },
    name3: {
      type: String,
      default: ''
    },
    x3: {
      type: Array,
      default () {
        return []
      }
    },
    xx3: {
      type: Array,
      default () {
        return []
      }
    },
    y3: {
      type:Array,
      default () {
        return []
      }
    },
    name4: {
      type: String,
      default: ''
    },
    x4: {
      type: Array,
      default () {
        return []
      }
    },
    xx4: {
      type: Array,
      default () {
        return []
      }
    },
    y4: {
      type:Array,
      default () {
        return []
      }
    },
    name5: {
      type: String,
      default: ''
    },
    x5: {
      type: Array,
      default () {
        return []
      }
    },
    xx5: {
      type: Array,
      default () {
        return []
      }
    },
    y5: {
      type:Array,
      default () {
        return []
      }
    },
    height: {
      type: Number,
      default: 380
    },
    title: {
      type: String,
      default: ''
    },
    marginBottom: {
      type: Number,
      default: 120
    },
    marginTop: {
      type: Number,
      default: 10
    },
    round: {
      type: Number,
      default: 0
    },
    tickangle: {
      type: Number,
      default: 70
    },
    tickfont_size:{
      type: Number,
      default:8
    },
    showlegend: {
      type: Boolean,
      default: true
    },
    legendAnchor: {
      type: Number,
      default: 1
    },
    legend_fontsize:{
      type: Number,
      default: 8
    },
    ytitle: {
      type: String,
      default: ''
    },
    isLandelijk: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      colorbar1: 'rgba(180,180,180,1.00)',
      colorbar2: 'rgba(180,180,180,0.80)',
      colorbar3: 'rgba(180,180,180,0.60)',
      colorbar4: 'rgba(180,180,180,0.40)',
      colorbar5: 'rgba(180,180,180,0.20)',
      layout: {
        uniformtext:{
          minsize:10,
          mode:'hide'},
        hovermode: 'closest',
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
        margin: {
          l: 50,
          r: 0,
          b: this.marginBottom,
          t: this.marginTop},
        barmode: 'stack',
        showlegend: this.showlegend,
        legend: {
          x: this.legendAnchor,
          y:  1,
          font: this.legend_fontsize
        },
        xaxis: {
          automargin: true,
          title:{
            standoff: 20},
          dividercolor: 'transparent',
          type: 'multicategory',
          tickangle: this.tickangle,
          tickfont:{
            size:this.tickfont_size
          },
          dtick:1
        },
        yaxis: {
          automargin: true,
          title: {
            standoff: 20,
            text: this.ytitle,
            font: {
              family: 'Gotham',
              size: 12
            },
          },
          tickformat: ',.0%',
        },
        height: this.height,
        // title: {
        //   text: this.title,
        //   font: {
        //     family: 'Gotham',
        //     size: 12
        //   },
        //   xref: 'paper',
        //   x: 0.05,
        //   y: 0.99
        // },
        annotations: [
        {
          text: this.title,
          showarrow: false,
          x: 0.5, 
          y: -0.20, 
          xref: 'paper',
          yref: 'paper',
          xanchor: 'center', // centers the text horizontally
          yanchor: 'top',
          font: {
            family: 'Gotham',
            size: 12
          }
        }
      ]
      },
      options: {
        displayModeBar: false,
        responsive: true,
        maintainAspectRatio: false
      },
    }
  },
  computed: {
    data () {
      return [
        {
          type: 'bar',
          name: this.name1,
          x: [this.x1,this.xx1],
          y:this.y1,
          text: this.y1.map(d=>(d*100).toFixed(this.round)) ,
          // for adjusting direct labels
          textfont:{size:8},
          textposition:  'inside',
          textangle : 0,
          marker: {
            color: this.colorbar1
          }
        },
        {
          type: 'bar',
          name: this.name2,
          x: [this.x2,this.xx2],
          y:this.y2,
          text: this.y2.map(d=>(d*100).toFixed(this.round)) ,
          textfont:{size:8},
          textposition:  'inside',
          textangle : 0,
          marker: {
            color: this.colorbar2
          }
        },
        { type: 'bar',
        name: this.name3,
        x: [this.x3,this.xx3],
        y: this.y3,
        text: this.y3.map(d=>(d*100).toFixed(this.round)) ,
        textfont:{size:8},
        textposition:  'inside',
        textangle : 0,
        marker: {
          color: this.colorbar3
          }
        },
        { type: 'bar',
        name: this.name4,
        x: [this.x4,this.xx4],
        y: this.y4,
        text: this.y4.map(d=>(d*100).toFixed(this.round)) ,
        textfont:{size:8},
        textposition:  'inside',
        textangle : 0,
        hoverinfo: this.x4,
        marker: {
          color: this.colorbar4
          }
        },
        { type: 'bar',
        name: this.name5,
        x: [this.x5,this.xx5],
        y: this.y5,
        text: this.y5.map(d=>(d*100).toFixed(this.round)) ,
        textfont:{size:8},
        textposition:  'inside',
        textangle : 0,
        hoverinfo: 'none',
        marker: {
          color: this.colorbar5
          }
        }
      ]
    }
  },

  watch: {
    '$store.state.selectedRegion': function () {
      this.getColors(this.x1, this.x2, this.x3, this.x4, this.x5)
    },
    x1 () {
      this.getColors(this.x1, this.x2, this.x3, this.x4, this.x5)
    }
  },
  mounted () {
    this.getColors(this.x1, this.x2, this.x3, this.x4, this.x5)
  },
  methods: {
    getColors (x1, x2, x3, x4, x5) {
      var colorArray1 = [];
      var colorArray2 = [];
      var colorArray3 = [];
      var colorArray4 = [];
      var colorArray5 = [];
      for (var i = 0; i<x1.length; i++) {
        if (x1[i] === this.$store.state.selectedRegion) {
          colorArray1.push('rgba(20,66,115,1.00))')
        }
        else if (x1[i] === 'Landelijk' && this.isLandelijk) {
          colorArray1.push('rgba(51,127,100,1.00))')
        }
        else {
          colorArray1.push('rgba(140, 140, 140,1.00)')
        }
      }
      for (var j = 0; j<x2.length; j++) {
        if (x2[j] === this.$store.state.selectedRegion) {
          colorArray2.push('rgba(20,66,115,0.6))')
        }
        else if (x2[j] === 'Landelijk' && this.isLandelijk) {
          colorArray2.push('rgba(51,127,100,0.6))')
        }
        else {
          colorArray2.push('rgba(140, 140, 140,0.6)')
        }
      }
      for (var k = 0; k<x3.length; k++) {
        if (x3[k] === this.$store.state.selectedRegion) {
          colorArray3.push('rgba(20,66,115,0.4))')
        }
        else if (x3[k] === 'Landelijk' && this.isLandelijk) {
          colorArray3.push('rgba(51,127,100,0.4))')
        }
        else {
          colorArray3.push('rgba(140, 140, 140,0.4)')
        }
      }
      for (var l = 0; l<x4.length; l++) {
        if (x4[l] === this.$store.state.selectedRegion) {
          colorArray4.push('rgba(20,66,115,0.3))')
        }
        else if (x4[l] === 'Landelijk' && this.isLandelijk) {
          colorArray4.push('rgba(51,127,100,0.3))')
        }
        else {
          colorArray4.push('rgba(140, 140, 140,0.3)')
        }
      }
      for (var m = 0; m<x5.length; m++) {
        if (x5[m] === this.$store.state.selectedRegion) {
          colorArray5.push('rgba(20,66,115,0.2))')
        }
        else if (x5[m] === 'Landelijk' && this.isLandelijk) {
          colorArray5.push('rgba(51,127,100,0.2))')
        }
        else {
          colorArray5.push('rgba(140, 140, 140,0.2)')
        }
      }
      this.colorbar1 = colorArray1
      this.colorbar2 = colorArray2
      this.colorbar3 = colorArray3
      this.colorbar4 = colorArray4
      this.colorbar5 = colorArray5
    }
  }
}

</script>
