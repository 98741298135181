import { render, staticRenderFns } from "./Leven-met-Dementie-vermogen.vue?vue&type=template&id=d24397e2&scoped=true"
import script from "./Leven-met-Dementie-vermogen.vue?vue&type=script&lang=js"
export * from "./Leven-met-Dementie-vermogen.vue?vue&type=script&lang=js"
import style0 from "./Leven-met-Dementie-vermogen.vue?vue&type=style&index=0&id=d24397e2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d24397e2",
  null
  
)

export default component.exports